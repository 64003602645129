<template>
    <div>
        <div v-if="voucherId" v-loading="$waiting.is('loading.vouchers.*')">
            <div class="card-tail">
                <div class="flex justify-between">
                    <el-form class="flex space-x-2" ref="form" :model="formData" :rules="rules">
                        <el-form-item label="">
                            <el-input v-model="formData.series" disabled />
                        </el-form-item>
                        <el-form-item label="" prop="date">
                            <el-input v-model="formData.date" disabled />
                        </el-form-item>
                        <el-form-item label="" prop="text">
                            <el-input v-model="formData.text" disabled />
                        </el-form-item>
                    </el-form>
                    <div>
                        <el-button class="self-start" type="default" @click="reset">Återställa</el-button>
                        <el-button class="self-start" type="warning" @click="flipBack" :disabled="flipButtonDisabled">Vänd</el-button>
                    </div>
                </div>
                <VouchersTable
                    :items-data="itemsData"
                    :accounts-data="accountsData"
                    @saveProjectLinks="saveProjectLinks"
                    @createVoucher="editVoucher"
                    @newItemCreation="createNewItemComponent"
                    @removeItem="removeItem"
                    @updateValues="updateValues"
                />

                <div class="flex justify-end mt-3">
                    <el-button type="default" @click="cancel">Avbryt</el-button>
                    <el-button type="primary" @click="editVoucher" :disabled="cantCreateVoucher">Spara</el-button>
                </div>
            </div>

            <div class="card-tail">
                <FilesHandler v-loading="$waiting.is('loading.files')" :files-data="filesData" @delete="deleteFile" @uploadFile="uploadFile" />
            </div>
        </div>
        <div v-else class="flex flex-col items-center py-10">
            <img src="@/assets/images/no_data.svg" class="w-32" />
            <p class="mt-4">Ingen data har valts</p>
        </div>
    </div>
</template>
<script>
import Api from "../../lopande.api";

export default {
    components: {
        VouchersTable: () => import(/* webpackChunkName: "VouchersTable" */ "@/components/vouchersTable/VouchersTable.vue"),
        FilesHandler: () => import(/* webpackChunkName: "VouchersTable" */ "./VoucherEditFiles.vue"),
    },

    data() {
        return {
            flipButtonDisabled: false,
            accountsData: [],
            seriesData: [],
            filesData: [],
            warningElements: [],
            formData: {
                series: "",
                date: "",
                text: "",
            },
            itemsData: [],
            rules: {
                series: [{ required: true, message: "Required", trigger: "blur" }],
                date: [{ required: true, message: "Required", trigger: "blur" }],
                comment: [{ required: true, message: "Required", trigger: "blur" }],
            },
        };
    },

    computed: {
        debitSummary() {
            const summary = this.itemsData.reduce((total, amount) => {
                const result = total + amount.debit;
                return result.roundToEven2();
            }, 0);
            return summary;
        },

        creditSummary() {
            const summary = this.itemsData.reduce((total, amount) => {
                const result = total + amount.credit;
                return result.roundToEven2();
            }, 0);
            return summary;
        },

        cantCreateVoucher() {
            return this.debitSummary === 0 || this.creditSummary === 0 || this.debitSummary !== this.creditSummary;
        },
    },

    props: {
        clientId: {
            type: String,
            default: "",
        },
        voucherId: {
            type: Number,
            default: null,
        },
    },

    watch: {
        voucherId() {
            if (!this.voucherId) return;

            this.getAccounts();
            this.getSeries();
            this.getVoucherDetails();
        },
    },

    methods: {
        async saveProjectLinks(payload) {
            console.log("emit dim 3");
            await Api.createVoucherTransactionLink(this.clientId, payload);
        },

        async getVoucherDetails() {
            this.$waiting.start("loading.vouchers.details");
            this.itemsData = [];
            this.filesData = [];
            let data = await Api.getVoucherDetails({ clientId: this.clientId, voucherId: this.voucherId });
            this.filesData = data.documents;
            this.formData.series = data.number;
            this.formData.date = data.date;
            this.formData.text = data.text;
            this.itemsData = data.transactions;
            this.itemsData.forEach(item => (item.readOnly = true));
            this.itemsData.push({
                id: 999999,
                account: null,
                debit: 0,
                credit: 0,
                text: "",
            });
            this.flipButtonDisabled = false;
            this.$waiting.end("loading.vouchers.details");
        },

        async getFiles() {
            this.$waiting.start("loading.files");
            let data = await Api.getVoucherDetails({ clientId: this.clientId, voucherId: this.voucherId });
            this.filesData = data.documents;
            this.$waiting.end("loading.files");
        },

        async getAccounts() {
            this.$waiting.start("loading.vouchers.accounts");
            this.accountsData = await Api.getAccounts(this.clientId);

            this.itemsData.forEach(item => {
                const account = this.accountsData.find(account => account.number === item.account);

                if (account || !item.readOnly) return;

                this.accountsData.push({
                    number: item.account,
                    name: item.accountName,
                });
            });

            this.$waiting.end("loading.vouchers.accounts");
        },

        async getSeries() {
            this.$waiting.start("loading.vouchers.series");
            this.seriesData = await Api.getSeries();
            this.$waiting.end("loading.vouchers.series");
        },

        createNewItemComponent(item) {
            this.itemsData.push(item);
        },

        removeItem(index) {
            let newItems = this.itemsData;
            const lastRow = index === newItems.length - 1;
            newItems.splice(index, 1);
            this.itemsData = newItems;

            if (this.itemsData.filter(item => !item.readOnly).length === 0 || lastRow) {
                this.itemsData.push({
                    id: Math.random(),
                    account: null,
                    debit: 0,
                    credit: 0,
                    text: "",
                });
            }
        },

        updateValues({ index, account, debit, credit, comment }) {
            this.itemsData[index].account = account;
            this.itemsData[index].debit = debit;
            this.itemsData[index].credit = credit;
            this.itemsData[index].text = comment;

            this.flipButtonDisabled = true;
        },

        async editVoucher() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            this.warningElements = this.itemsData.filter(item => item.debit === 0 && item.credit === 0).map(item => item.id);
            const confirm = await this.$dialog.title("Bekräftelse").confirm("Är du säker?");
            if (!confirm) return;

            let formattedData = this.itemsData.map(item => {
                return {
                    transactionId: item.transactionId || 0,
                    account: item.account,
                    registerDate: item.registerDate || null,
                    text: item.text,
                    debit: item.debit,
                    credit: item.credit,
                };
            });
            formattedData = formattedData.filter(item => item.account);

            await Api.editVoucher({
                clientId: this.clientId,
                voucherId: this.voucherId,
                date: this.formData.date,
                text: this.formData.text,
                transactions: formattedData,
            });

            this.$notify.success({ title: "Utfört" });

            this.$emit("refresh");
            this.$emit("cancel");
        },

        flipBack() {
            this.flipButtonDisabled = true;
            this.itemsData.pop();
            const newData = this.itemsData.map(item => {
                return {
                    id: Math.random(),
                    account: item.account,
                    debit: item.credit,
                    credit: item.debit,
                    text: item.text,
                };
            });

            this.itemsData = [
                ...this.itemsData,
                ...newData,
                {
                    id: 999999,
                    account: null,
                    debit: 0,
                    credit: 0,
                    text: "",
                },
            ];
        },

        cancel() {
            this.$emit("cancel");
        },

        async reset() {
            this.flipButtonDisabled = false;

            this.$delete(
                this.itemsData,
                this.itemsData.findIndex(item => item.id === 999999),
            );

            await this.$nextTick();

            this.itemsData = [
                ...this.itemsData.filter(item => item.readOnly),
                {
                    id: 999999,
                    account: null,
                    debit: 0,
                    credit: 0,
                    text: "",
                },
            ];
        },

        async deleteFile({ documentId, filesCount }) {
            const confirm = await this.$dialog.title("Bekräftelse").confirm("Är du säker?");
            if (!confirm) return;
            this.$waiting.start("loading.files");
            this.$emit("filesChanged", filesCount);

            await Api.deleteFile({
                clientId: this.clientId,
                voucherId: this.voucherId,
                documentId: documentId,
            });

            this.$notify.success({ title: "Utfört" });
            this.getFiles();
        },

        async uploadFile(file) {
            this.$waiting.start("loading.files");
            const fileType = {
                "application/pdf": 1,
                "image/jpeg": 4,
                "image/png": 5,
            };

            const data = await Api.uploadFile({
                clientId: this.clientId,
                voucherId: this.voucherId,
                fileContent: await this.toBase64(file.raw),
                fileType: fileType[file.raw.type],
                fileName: file.name,
            });

            if (data) {
                this.$emit("filesChanged", this.filesData.length + 1);
                this.$notify.success({ title: "Utfört" });
            }

            this.getFiles();
        },

        async toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(",").pop());
                reader.onerror = error => reject(error);
            });
        },
    },
};
</script>
